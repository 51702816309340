/**
 * @TODO rozszerzyć lokalny css zamiast korzystać w pełni z Text
 */
import * as React from "react";
import { Variants } from "@nju/css";

import { styles as textStyles } from "../Text/Text";

type BlockTextStyleProps = Variants<typeof textStyles["element"]>;

export type BlockTextProps = {
  className?: string;
  html?: string;
  testId?: string;
} & BlockTextStyleProps;

export function BlockText({
  display = "block",
  size,
  tone,
  weight,
  align,
  font,
  children,
  html,
  className = "",
  breakLongValue,
  testId,
}: React.PropsWithChildren<BlockTextProps>) {
  return (
    <div
      {...(html ? { dangerouslySetInnerHTML: { __html: html } } : {})}
      className={`${textStyles.element({
        size,
        tone,
        weight,
        align,
        font,
        display,
        breakLongValue,
      })} ${className}`}
      data-cy={testId}
    >
      {!html ? children : undefined}
    </div>
  );
}
