import * as React from "react";
import { marked } from "marked";

interface IMarkdownProps {
  content: string;
  as?: keyof JSX.IntrinsicElements;
  className?: string;
}

const resolveOptions = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const images = doc.querySelectorAll("img");

  for (const img of images) {
    if (img.alt && !Number.isNaN(Number.parseInt(img.alt))) {
      img.style.width = `${img.alt}px`;
    }
  }

  return doc.body.innerHTML;
};

export const Markdown = React.memo<IMarkdownProps>(function MarkdownComponent({
  content: markdown,
  as = "div",
  className,
}) {
  const html = useMarkdown(markdown);
  const Cmp = as;
  const adjustedHtml = resolveOptions(html);

  return (
    <Cmp
      className={className}
      dangerouslySetInnerHTML={{ __html: adjustedHtml }}
    />
  );
});

export function useMarkdown(markdown: string) {
  return React.useMemo(() => {
    return marked(markdown);
  }, [markdown]);
}
