import * as React from "react";

import { styled, Variants } from "@nju/css";

type HeadingStyleProps = Omit<Variants<typeof StyledHeading>, "variant">;

interface HeadingOwnProps {
  /**
   * You can't change tag just like other variants so,
   * this one is handled like regular prop with single value.
   */
  variant: "h1" | "h2" | "h3" | "h4" | "dashboardTitle";
  level?: "h1" | "h2" | "h3" | "h4";
  className?: string;
  testId?: string;
}

export interface HeadingProps extends HeadingOwnProps, HeadingStyleProps {}

export const Heading = (props: React.PropsWithChildren<HeadingProps>) => {
  const { variant, testId, ...rest } = props;
  return (
    <StyledHeading
      as={props.level || variant || "h2"}
      data-cy={testId}
      variant={variant}
      {...rest}
    />
  );
};

const StyledHeading = styled("h2", {
  lineHeight: "$1",
  variants: {
    variant: {
      dashboardTitle: {
        fontSize: "$30",
        "@fromTablet": {
          fontSize: "$40",
        },
      },
      h1: {
        fontSize: "$40",
        "@fromTablet": {
          fontSize: "$63",
        },
      },
      h2: {
        fontSize: "$23",
        "@fromTablet": {
          fontSize: "$32",
        },
        "@fromDesktop": {
          fontSize: "$40",
        },
      },
      h3: {
        fontSize: "$20",
        "@fromTablet": {
          fontSize: "$24",
        },
        "@fromDesktop": {
          fontSize: "$34",
        },
      },
      h4: {
        fontSize: "$16",
        "@fromTablet": {
          fontSize: "$20",
        },
        "@fromDesktop": {
          fontSize: "$24",
        },
      },
    },
    tone: {
      neutral: {
        color: "$daintree",
      },
      accent: {
        color: "$cerulean",
      },
      secondary: {
        color: "$white",
      },
      info: {
        color: "$ripeLemon",
      },
    },
    /**
     * Nazwać 'info' (bo kolor ripeLemon) ?
     */
    underline: {
      accent: {
        textDecoration: "underline",
        textDecorationColor: "$ripeLemon",
        textDecorationThickness: "8px",
      },
      neutral: {
        textDecoration: "underline",
        textDecorationColor: "$geyser",
        textDecorationThickness: "3px",
      },
      none: {},
    },
    weight: {
      bold: {
        fontWeight: "bold",
      },
    },
    align: {
      left: {
        textAlign: "left",
      },
      right: {
        textAlign: "right",
      },
      center: {
        textAlign: "center",
      },
    },
    font: {
      ttfirs: {
        fontFamily: "$ttfirs",
      },
      crocante: {
        fontFamily: "$crocante",
      },
    },
  },
  defaultVariants: {
    variant: "h2",
    tone: "secondary",
    align: "left",
    font: "crocante",
  },
});
