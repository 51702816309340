import * as React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion";
import {
  BlockContent,
  Heading,
  Separator,
  Text,
  Loader,
  Markdown,
} from "@nju/ui";
import { Icon } from "@nju/icons";
import { cx } from "@nju/css";
import {
  fetchFAQ,
  IFaqCategory,
  IFaqCategoryName,
} from "@nju/data-access-portal-help";

interface IFAQBannerProps {
  categoryName: IFaqCategoryName;
}

type IStates = "IDLE" | "LOADING" | "ERROR" | "DONE";

export function FAQBanner({ categoryName }: IFAQBannerProps) {
  const [currentState, setCurrentState] = React.useState<IStates>("IDLE");
  const [data, setData] = React.useState<IFaqCategory | undefined>();

  React.useEffect(() => {
    setCurrentState("LOADING");
    fetchFAQ({ categoryId: categoryName }).then((result) => {
      if (result.isOk) {
        setData(result.value[0]);

        return setCurrentState("DONE");
      }
      return setCurrentState("ERROR");
    });
  }, [categoryName]);

  if (currentState === "IDLE" || currentState === "LOADING") {
    return (
      <BlockContent
        grid="narrow"
        spacing={{
          "@onlyMobile": "l",
          "@fromTablet": "xl",
        }}
        tone="secondary"
      >
        <div className="flex justify-center">
          <Loader size="50px" tone="dark" />
        </div>
      </BlockContent>
    );
  }

  if (!data) {
    return (
      <BlockContent
        grid="narrow"
        spacing={{
          "@onlyMobile": "l",
          "@fromTablet": "xl",
        }}
        tone="secondary"
      >
        {" "}
      </BlockContent>
    );
  }

  return (
    <BlockContent
      grid="narrow"
      spacing={{
        "@onlyMobile": "l",
        "@fromTablet": "xl",
      }}
      tone="secondary"
    >
      <Icon
        className="
          mx-auto mb-3 block w-[37px] h-[32px]
          from-tablet:mb-0 from-tablet:w-[54px] from-tablet:h-[48px]
          from-desktop:w-[69px] from-desktop:h-[64px]
        "
        name="comments-alt"
        tone="accent"
      />
      <Heading
        align="center"
        className="pt-2 pb-4 from-desktop:py-6"
        font="ttfirs"
        tone="neutral"
        variant="h2"
      >
        {data.categoryName.pl}
      </Heading>
      <Separator size="s" tone="invisible" />
      <Accordion collapsible={true} multiple={true}>
        {data.articles.map((element, index) => {
          return (
            <AccordionItem
              className={cx("py-3 pr-8 from-tablet:py-4", {
                "border-b-solid border-b-1 border-b-geyser":
                  data.articles.length - 1 !== index,
              })}
              key={index}
            >
              <AccordionButton className="flex items-center cursor-pointer [&[aria-expanded='true']_svg]:rotate-0">
                <div className="flex">
                  <Icon
                    className="mr-3 shrink-0 w-[24px] h-[24px] -rotate-180 from-tablet:w-[32px] from-tablet:h-[32px]"
                    name="chevron-up"
                    tone="neutral"
                  />
                  <Text
                    as="h3"
                    size={{
                      "@onlyMobile": "m",
                      "@fromTablet": "l",
                      "@fromDesktop": "xl",
                    }}
                  >
                    {element.title.pl}
                  </Text>
                </div>
              </AccordionButton>
              <AccordionPanel className="ml-7 mt-4 from-tablet:ml-9 [&_li]:m-3 [&_ul]:pl-5 [&_ol]:pl-5 [&_ul]:list-disc [&_ol]:list-disc">
                <Markdown
                  className="prose prose-sm from-tablet:prose-base"
                  content={element.content.pl}
                />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </BlockContent>
  );
}
