import Bugsnag from "@bugsnag/js";
import { client } from "@nju/http-client";
import { isError, ok } from "@nju/result";
import { sortByPriority } from "@nju/utils";
import { IFaqCategoryName, IFaqResult } from "./types";
import { serverResponse } from "./validators";

interface IParams {
  categoryId?: IFaqCategoryName;
}

export async function fetchFAQ({
  categoryId,
}: IParams = {}): Promise<IFaqResult> {
  const result = await client(
    `${process.env.NEXT_PUBLIC_ANONYMOUS_GATEWAY_HOST}/faq`,
    {
      validator: serverResponse,
      searchParams: {
        brand: "NJU",
        _sort: "priority",
        state: "PUBLISHED",
        ...(!categoryId && { displayWeb: true }),
        ...(categoryId && { categoryId }),
      },
    }
  );

  if (isError(result)) {
    Bugsnag.notify(result.err);
    return result;
  }

  const categories = result.value.map((category) => {
    return {
      ...category,
      articles: category.articles.sort(sortByPriority),
    };
  });

  return ok(categories);
}
