import { css, keyframes } from "@nju/css";

export function CircleLoader() {
  return (
    <div className={wrapper()}>
      <div className={circle({ order: "1" })}></div>
      <div className={circle({ order: "2" })}></div>
      <div className={circle({ order: "3" })}></div>
      <div className={circle({ order: "4" })}></div>
    </div>
  );
}

const t1 = keyframes({
  "0%": {
    left: "-30%",
  },
  "100%": { left: "165%" },
});

const t2 = keyframes({
  "0%": { left: "-45%", opacity: 0 },
  "20%": { opacity: 0 },
  "55%": { opacity: 0.7 },
  "100%": { left: "150%" },
});

const t3 = keyframes({
  "0%": { left: "-60%", opacity: 0 },
  "25%": { opacity: 0 },
  "60%": { opacity: 0.4 },
  "100%": { left: "135%" },
});

const t4 = keyframes({
  "0%": { left: "-75%", opacity: 0 },
  "30%": { opacity: 0 },
  "65%": { opacity: 0.2 },
  "100%": { left: "120%" },
});

const wrapper = css({
  width: "100px",
  height: "100px",
  backgroundColor: "$cerulean",
  borderRadius: "$round",
  overflow: "hidden",
  position: "relative",
});

const circle = css({
  width: "33.333333%",
  height: "33.333333%",
  borderRadius: "9999999px",
  backgroundColor: "$ripeLemon",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  animationDuration: "1.3s",
  animationIterationCount: "infinite",
  animationTimingFunction: "linear",
  variants: {
    order: {
      "1": {
        left: "-30%",
        animationName: `${t1}`,
      },
      "2": {
        left: "-45%",
        opacity: 0.7,
        animationName: `${t2}`,
      },
      "3": {
        left: "-60%",
        opacity: 0.4,
        animationName: `${t3}`,
      },
      "4": {
        left: "-75%",
        opacity: 0.2,
        animationName: `${t4}`,
      },
    },
  },
});
