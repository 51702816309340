import { A, BlockText, BlockContent, Heading, Text, Separator } from "@nju/ui";
import { portalRoutes } from "@nju/routes";
import Link from "next/link";

export function CaptiveBanner() {
  return (
    <div className="bg-cerulean">
      <BlockContent overflow={{ "@onlyMobile": true, "@fromTablet": "y" }}>
        <div className="grid pt-6 pb-5 from-tablet:min-h-[600px] from-tablet:grid-cols-[1fr_0.8fr]">
          <div>
            <Heading
              align={{
                "@onlyMobile": "center",
              }}
              variant="h1"
            >
              Masz ograniczony dostęp do internetu
            </Heading>
            <Separator size="xxs" tone="invisible" />
            <BlockText
              align={{
                "@onlyMobile": "center",
              }}
              font="ttfirs"
              size={{
                "@onlyMobile": "m",
                "@fromTablet": "xl",
                "@fromDesktop": "keyS",
              }}
              tone="secondary"
            >
              Co zrobić, żeby odblokować internet?
              <Separator size="none" tone="invisible" />
              Zaloguj się do swojego konta nju
              <Separator size="none" tone="invisible" />
              światłowód i zapłać za usługę.
            </BlockText>
            <Separator size="xxs" tone="invisible" />
            <div className="pt-4 max-w-[338px] only-mobile:mx-auto from-tablet:pt-5">
              <Link href={portalRoutes.zaloguj.index.get()} passHref={true}>
                <A
                  size={{
                    "@onlyMobile": "s",
                    "@fromTablet": "m",
                  }}
                >
                  <Text size="l">zaloguj się</Text>
                </A>
              </Link>
            </div>
          </div>
          <div className="h-full relative pt-7 from-tablet:pt-0">
            <div
              className="
              bg-[url(/images/pipik_no_internet.svg)] bg-no-repeat bg-top bg-100%
              only-mobile:mx-auto only-mobile:my-0 only-mobile:h-[230px] only-mobile:w-[300px]
              from-tablet:absolute from-tablet:bottom-[-25%] from-tablet:left-[-25%] from-tablet:h-[500px] from-tablet:w-[500px] from-tablet:z-up
              from-desktop:bottom-[-43%] from-desktop:left-[-30%] from-desktop:h-[700px] from-desktop:w-[700px]
            "
            />
          </div>
        </div>
      </BlockContent>
    </div>
  );
}
