import { useMachine } from "@xstate/react";
import { BlockContent, BlockText, CircleLoader, Markdown } from "@nju/ui";
import { footerContentMachine } from "./footerContent.machine";
import { IFaqCategoryName } from "@nju/data-access-portal-help";

interface IFooterContentProps {
  categoryId: IFaqCategoryName;
}

export function FooterContent({ categoryId }: IFooterContentProps) {
  const [current] = useMachine(footerContentMachine, {
    context: {
      categoryId,
    },
  });

  const { matches, context } = current;
  const { article } = context;

  if (matches("fetchData")) {
    return (
      <BlockContent border="none" rounded="primary" spacing="l" tone="none">
        <div className="flex justify-center items-center h-full">
          <CircleLoader />
        </div>
      </BlockContent>
    );
  }

  if (matches("error") || !article) {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  return (
    <>
      {article.title.pl && (
        <div className="mb-4">
          <BlockText weight="bold">
            <Markdown as="div" content={article.title.pl} />
          </BlockText>
        </div>
      )}
      {article.content.pl && (
        <BlockText>
          <Markdown as="div" content={article.content.pl} />
        </BlockText>
      )}
    </>
  );
}
