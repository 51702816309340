import { z } from "zod";

const categoryName = z.object({
  id: z.number(),
  en: z.string().or(z.null()),
  pl: z.string(),
  uk: z.string().or(z.null()),
});

const article = z.object({
  articleId: z.string(),
  isPopular: z.boolean().or(z.null()),
  priority: z.number(),
  appScreenName: z.string().or(z.null()),
  title: categoryName,
  content: categoryName,
});

const faq = z.object({
  categoryId: z.string(),
  categoryIcon: z.string(),
  state: z
    .literal("DRAFT")
    .or(z.literal("PUBLISHED"))
    .or(z.literal("ARCHIVED")),
  priority: z.number(),
  displayMobile: z.boolean(),
  displayWeb: z.boolean(),
  brand: z.string(),
  categoryName: categoryName,
  articles: z.array(article),
});

export const serverResponse = z.array(faq);

export type IFaqCategory = z.infer<typeof faq>;
export type IArticle = z.infer<typeof article>;
