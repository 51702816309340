import { FAQBanner } from "@nju/feature-portal-banners";
import { CaptiveBanner } from "@nju/feature-portal-technical";
import { Footer, FooterContent } from "@nju/feature-portal-footer";
import { Header } from "@nju/feature-portal-header";
import { LayoutWrapper } from "@nju/ui";

export default function Captive() {
  return (
    <LayoutWrapper>
      <Header />
      <CaptiveBanner />
      <FAQBanner categoryName="NJU_CAPTIVE_WEB" />
      <Footer>
        <FooterContent categoryId="NJU_WWW_LANDING_FOOTER_FTTH" />
      </Footer>
    </LayoutWrapper>
  );
}
