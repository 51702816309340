import { assign, createMachine } from "xstate";
import {
  fetchFAQ,
  IArticle,
  IFaqCategoryName,
} from "@nju/data-access-portal-help";
import { BasicError, isError } from "@nju/result";

interface IContext {
  categoryId?: IFaqCategoryName;
  article?: IArticle;
}

type IEvent = { type: "ERROR" } | { type: "SUCCESS"; article: IArticle };

export const footerContentMachine = createMachine(
  {
    schema: {
      context: {} as IContext,
      events: {} as IEvent,
    },
    initial: "fetchData",
    states: {
      fetchData: {
        invoke: {
          src: "fetchData",
        },
        on: {
          SUCCESS: {
            actions: "setData",
            target: "success",
          },
          ERROR: {
            target: "error",
          },
        },
      },
      success: {
        type: "final",
      },
      error: {
        type: "final",
      },
    },
  },
  {
    actions: {
      setData: assign<IContext, IEvent>((context, event) => {
        if (event.type === "SUCCESS") {
          return {
            article: event.article,
          };
        }

        return context;
      }),
    },
    services: {
      fetchData: ({ categoryId }) => {
        return async (callback) => {
          if (!categoryId) {
            throw new BasicError({
              message: "Missing categoryId",
            });
          }

          const result = await fetchFAQ({ categoryId });

          if (isError(result)) {
            return callback({ type: "ERROR" });
          }

          return callback({
            type: "SUCCESS",
            article: result.value[0]?.articles[0],
          });
        };
      },
    },
  }
);
